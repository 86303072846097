import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { Company } from 'src/app/Settings/company-settings/shared/models/Company';
import { ValidateVatResponse } from '../models/ValidateVatResponse';
import { BaseResponse } from 'src/shared/models/response.model';
import { Observable } from 'rxjs';
import { Country } from '../models/Country';

@Injectable({
  providedIn: 'root',
})
export class CompanyManagementService {
  protected ManagementServiceUrl = AppConfig.settings.apiServer.managementServiceUrl;

  constructor(private http: HttpClient)
  { }

  getCompanybyBoardID(boardID: string): Observable<any> {
    const url = `${this.ManagementServiceUrl}/company/GetCompanybyBoardID?BoardID=${boardID}`;
    return this.http.get<any>(url).pipe(
      map(response => {
        // Assuming the response is already in the correct format
        console.log("engy melad hanna "+ response.data)
        return response.data as Company;

      })
    );
  }

  getCountries(): Observable<any> {
    const url = `${this.ManagementServiceUrl}/Country/GetCountries`;
    return this.http.get<any>(url).pipe(
      map(response => {
        // Assuming the response is already in the correct format
        return response.data ;
      })
    );
  }

  ValidateVatID(CountryCode: string, IsEU: boolean, VatNumber: string, BoardId: string): Observable<Company> {
   // const url = `${this.ManagementServiceUrl}/ValidateVatID?CountryCode=${CountryCode},IsEU=${IsEU},VatNumber=${VatNumber},BoardId=${BoardId}`;
    const url = `${this.ManagementServiceUrl}/Company/ValidateVatID?CountryCode=${String(CountryCode)}&IsEU=${IsEU}&VatNumber=${String(VatNumber)}&BoardId=${String(BoardId)}`;
    return this.http.get<any>(url).pipe(
      map(response => {
        // Assuming the response is already in the correct format
        console.log("engy "+response.data);
        console.log(response.data.companyName);
        const company: Company = {
         // vatid: response.vatNumber,
          isValid: response.data.isValid,
          legalName: response.data.companyName,
          address: response.data.companyAddress,
          // Optionally map other properties if needed
          //companyType: response.isValid ? 'Valid' : 'Invalid', // Example logic
          //boardId: BoardId // Assuming BoardId is passed as a parameter
        };
        return company;
      })
    );
  }

  UpdateCompany(company:Company) {
    const url = `${this.ManagementServiceUrl}/company/UpdateCompany`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(company);
    return this.http.put<any>(url, body, { headers }).pipe(
      map(response => {
        console.log(response);
        return response ;
      })
    );
  }
}