import { Component, Input,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '../company-settings/shared/models/Company';
import { CompanyManagementService } from '../company-settings/shared/services/companyManagement.service';
import { Router } from '@angular/router';
import { LabelService } from 'src/app/admin-tools/label-management/shared/services/label.service';
import { SpinnerService } from 'src/core/services/spinner.service';
import { Label } from 'src/app/admin-tools/label-management/shared/models/label.model';
import { finalize } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-super-admin-settings',
  templateUrl: './super-admin-settings.component.html',
  styleUrls: ['./super-admin-settings.component.scss']
})
export class SuperAdminSettingsComponent {
  private _companyDetails!: Company;

  companyForm: FormGroup;
animationState: string = '';
displayDeleteLabelDialog: boolean = false;
showError: boolean = false;
isInputValid: boolean = false;
selectedLabel: string='';
deleteLabelValue: string = '';
parentLabels: any[] = [];
organization: string = 'dk';
CollectDirectly: boolean = false ;
Denmark: boolean = false ;
Norway: boolean = false ;
useNemkonto: boolean = false ;
toggleSwitchIdentifier: boolean = false ;



@Input() set companyDetails(value: Company) {
  this._companyDetails = value;
  this.CollectDirectly = this._companyDetails.collectDirectly?? false;
  this.Denmark = this._companyDetails.registeredCountry =='Denmark'?true: false;
  this.Norway = this._companyDetails.registeredCountry =='Norway'?true: false;
  this.toggleSwitchIdentifier= (!this._companyDetails.financialIdentifier )?true:false;
  console.log(this.CollectDirectly);
  if (this._companyDetails) {
    this.companyForm.patchValue(this._companyDetails);
    this.selectedLabel = this._companyDetails.brandName ? this._companyDetails.brandName : 'Default Label';
  } 
}
ngOnInit() {
  this.organization = sessionStorage.getItem('organisation')!;
  this.getParentLabels();
}
ngAfterViewInit() {
  if (this.companyForm) {
    this.companyForm.valueChanges.subscribe((_) => {
      if (this.companyForm.dirty) {
       // this.reportsFormChange = true;
        // remove slide-down from div of class unsaved-changes-alert
        this.animationState = 'slide-up';
      }
    });
  } else {
    console.error('ngForm is not available');
  }
}
toggleCollectDirectly()
{
  this.CollectDirectly=!this.CollectDirectly;
}
toggleUseNemkonto()
{
  this.useNemkonto=!this.useNemkonto;
}
onToggleChange(event:Event){

  this.toggleSwitchIdentifier = !this.toggleSwitchIdentifier;
  //console.log(this.toggleSwitch);
  this.companyForm.patchValue({
    financialIdentifier:(this.toggleSwitchIdentifier )?this._companyDetails.vatid:this._companyDetails.financialIdentifier
  });
  this.UpdateCompany();
 // this.toggleSwitchIdentifier = !this.toggleSwitchIdentifier;
  }
checkInputContent() {
  this.isInputValid = this.deleteLabelValue === this.selectedLabel;
  this.showError = !this.isInputValid && this.deleteLabelValue !== '';
}
constructor(   private router: Router,private labelService: LabelService,private messageService: MessageService,
  private spinnerService: SpinnerService,private fb: FormBuilder,private companyService: CompanyManagementService) {
  this.companyForm = this.fb.group({
    memberType: ['', Validators.required],
    labelType: ['', Validators.required],
    collectDirectly: [''] ,
    parentBoardId: [''] ,
    useNemkonto: [''] ,
    dK_RegNo: [''] ,
    dK_AccountNo: [''] ,
    nO_AccountNo: [''] ,
    Intl_NameOfBank: [''] ,
    Intl_IBAN: [''] ,
    Intl_Swicfbic: [''] ,
    financialIdentifier: ['', { value: '', disabled: this.toggleSwitchIdentifier }] ,
   
  });
}
  public cancel():void{
    this.animationState = 'slide-down';
      setTimeout(() => {
        // Hide the save changes alert
        //this.reportsFormChange = false;
        //this.getDistribution();
        // how to clear  newDistributionForm  dirty
        this.companyForm.markAsPristine();
        this.animationState = '';
      }, 200);
  }
   
  UpdateCompany()
  {
    // Convert form value to Company instance
    const company: Company = {
      ...this._companyDetails,
      labelType : this.companyForm.value.labelType,
      memberType : this.companyForm.value.memberType,
      collectDirectly : this.companyForm.value.collectDirectly,
      dK_RegNo : this.companyForm.value.dK_RegNo,
      dK_AccountNo : this.companyForm.value.dK_AccountNo,
      nO_AccountNo : this.companyForm.value.nO_AccountNo,
      Intl_IBAN : this.companyForm.value.Intl_IBAN,
      Intl_NameOfBank : this.companyForm.value.Intl_NameOfBank,
      Intl_Swicfbic : this.companyForm.value.Intl_Swicfbic,
      financialIdentifier : this.companyForm.value.financialIdentifier,
      //id : this.companyData.id
    };
    this.companyService.UpdateCompany(company).subscribe({
      next: (response) => {
        console.log(response);
        this.messageService.add({
          severity: 'success',
          summary: 'Update',
          detail: 'Updated Successfuly',
        });
      },
      error: (error) => {
        console.error('Error fetching data', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Update',
          detail: 'Please Try Again',
        });
      }
    });
    this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      this.companyForm.markAsPristine();
      this.animationState = '';
    }, 200);
  }

  openDeleteLabelDialog() {
    //this.selectedLabel = label;
    this.displayDeleteLabelDialog = true;
  }

  closeDeleteLabelDialog() {
    this.displayDeleteLabelDialog = false;
  }

  deleteLabel() {
    const companyBoardId = this._companyDetails.boardId != null?this._companyDetails.boardId :'';
    var boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;
    this.spinnerService.show();
    this.labelService
      .deleteLabel(boardId, companyBoardId)
      .pipe(finalize(() => this.closeDeleteLabelDialog()))
      .subscribe({
        next: (message) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Delete',
            detail: 'Deleted Successfuly',
          });
       
          this.spinnerService.hide();
        },
        error: (error) => {
          this.spinnerService.hide();
        },
      });
  }
  getParentLabels(pageIndex: number = 0) {
    const subscription = this.labelService.getAllLabels(pageIndex)
    .subscribe((res: any) => {
      if(this.organization == 'dk')
        this.parentLabels.push(...res.filter((label: any) => label.parentBoard?.name == 'mpo'));
      else if(this.organization == 'no')
        this.parentLabels.push(...res.filter((label: any) => label.parentBoard?.name == 'Music Nest Norway'));
      if (res.length === 1000) this.getParentLabels(pageIndex + 1);  
      console.log(res);    
    });
  }


}
