import { Component, Input, OnInit } from '@angular/core';
import { CompanyManagementService } from './shared/services/companyManagement.service';
import { Company } from './shared/models/Company';
import { Country } from './shared/models/Country';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription, finalize, takeUntil } from 'rxjs';
import { SpinnerService } from 'src/core/services/spinner.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})

export class CompanySettingsComponent implements OnInit {
  //bsm saleeb
  companyData: Company = {}; // To hold the fetched data
  countryCode:string='';
  countryEU:boolean=true;
  DisconnectVatID:boolean=false;
  routerSubscription!: Subscription;
  newCompany: boolean=true;
  ValidVatID: boolean=true;

  animationState: string = '';
  countries:Country[]=[];
companyForm: FormGroup;
Denmark: boolean = false ;
Norway: boolean = false ;
public _companyDetails!: Company; // Private property to hold the input data


@Input() set companyDetails(value: Company) {
  this._companyDetails = value;
  if (Object.keys(this._companyDetails).length != 0) {
    this.newCompany = false;
    this.patchFormWithCompanyDetails();
    this.Denmark = this._companyDetails.registeredCountry =='Denmark'?true: false;
    this.Norway = this._companyDetails.registeredCountry =='Norway'?true: false;
  }
  else
  {
    this.spinnerService.hide();
    this.newCompany = true;
  }
}


  constructor(private companyService: CompanyManagementService,
    private fb: FormBuilder,private route: ActivatedRoute,private spinnerService:SpinnerService,
    private messageService: MessageService
  ) {
    this.companyForm = this.fb.group({
      companyType: ['', Validators.required],
      registeredCountry: ['', Validators.required],
      vatid: ['',Validators.required ], 
      brandName: [''], 
      legalName: [''], 
      city: [''],
      zip: [''], 
      address: [''], 
      boardId: ['']
    });
  }
  private patchFormWithCompanyDetails(): void {
    this.companyForm.patchValue(this._companyDetails);
    this.spinnerService.hide();
  }
  ngOnInit(): void {
    this.spinnerService.show();
    this.getCountries();
  }
getCountries()
{
  this.companyService.getCountries().subscribe(
    data => {
      this.countries = data; // Assign the retrieved data to the countries array
    },
    error => {
      console.error('Error fetching countries:', error); // Handle any errors
    }
  );
}
  onSubmit() {
    if (this.companyForm.valid) {
      const formValue = this.companyForm.value;
      console.log({
        // name: formValue.name,
        // code: formValue.code,
        // isEU: isEU,
      });
      // Here you can handle form submission to your backend or other logic
    }
  }
  validateVat()
  {
    this.spinnerService.show();
      this.countryCode=this.countries.find(x=>x.name==this.companyForm.value.registeredCountry)?.code || '';
       this.countryEU =this.countries.find(x=>x.name==this.companyForm.value.registeredCountry)?.isEU || false;

    this.companyService.ValidateVatID(this.countryCode,this.countryEU,this.companyForm.value.vatid,this.companyForm.value.boardId).subscribe({
      next: (response) => {
        //console.log(response);
        if(response.isValid)
        {
          this.companyForm.patchValue(response);
          this.companyForm.disable();
          this.toggleDisconnectVatID();
          this.ValidVatID=true;
          this.messageService.add({
            severity: 'success',
            summary: 'Validated VatID',
            detail: 'Valid VatID',
          });
          this.UpdateCompany();
        

        }
        else{
          this.ValidVatID=false;
          this.companyForm.enable();
        }
        this.spinnerService.hide();
      },
      error: (error) => {
        console.error('Error fetching data', error);
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Validate VatID Error',
          detail: 'Please Try Again',
        });
      }
    });
  }

  UpdateCompany()
  {
    // Convert form value to Company instance
    const company: Company = {
      ...this._companyDetails,
      legalName : this.companyForm.value.legalName,
      vatid : this.companyForm.value.vatid,
      city : this.companyForm.value.city,
      zip : this.companyForm.value.zip,
      address : this.companyForm.value.address 
      //id : this.companyData.id
    };
    this.companyService.UpdateCompany(company).subscribe({
      next: (response) => {
        console.log(response);
      },
      error: (error) => {
        console.error('Error fetching data', error);
      }
    });
    this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      this.companyForm.markAsPristine();
      this.animationState = '';
    }, 200);
  }
  toggleDisconnectVatID() {
    this.DisconnectVatID = !this.DisconnectVatID;
    if (this.DisconnectVatID) {
      this.companyForm.disable(); // Disable the form if DisconnectVatID is true
    } else {
      this.companyForm.enable(); // Enable the form if DisconnectVatID is false
    }
  }
  public cancel():void{
    this.animationState = 'slide-down';
      setTimeout(() => {
        // Hide the save changes alert
        //this.reportsFormChange = false;
        //this.getDistribution();
        // how to clear  newDistributionForm  dirty
        this.companyForm.markAsPristine();
        this.animationState = '';
      }, 200);
  }
  // public UpdateCompany():void{
  //   //console.log(this._companyDetails.id);
  //   const company: Company = {
  //     ...this._companyDetails, // Preserve all existing properties
  //     identifier: this.companyForm.value.identifier, // Update only the modified property
  //   };
  
  //   this.companyService.UpdateCompany(company).subscribe({
  //     next: (response) => {
  //       console.log(response);
  //     },
  //     error: (error) => {
  //       console.error('Error fetching data', error);
  //     }
  //   });
  //   this.animationState = 'slide-down';
  //   setTimeout(() => {
  //     // Hide the save changes alert
  //     this.companyForm.markAsPristine();
  //     this.animationState = '';
  //   }, 200);
  // }


}