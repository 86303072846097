<div class="card companyCoard">
    <div class="card-body">
        <form [formGroup]="companyForm">

            <div class="infobox yellow">
                <img src="../assets/images/shield-fill-exclamation.svg" alt="warning">
                <p>These settings can only be viewed and edited by Superadmins.</p>
            </div>

            <div class="row mt-4" style="border-bottom: none !important;;">
                <section class="paymentdetails">
                    <h4 class="headerPageCustom">Payment Details</h4>
                    <p class="paragraphCustom">
                        Choose whether the company collects remuneration directly or select a representative company.
                        Then,
                        update the payment details in the box below, ensuring accuracy, as this information is shared
                        across multiple systems.
                    </p>
                    <div class="toggles">
                        <div class="t-c">
                            <div class="toggle-row">
                                <div class="toggle-switch">
                                    <input type="checkbox" id="toggleSwitch1" formControlName="collectDirectly"
                                        (change)="toggleCollectDirectly()" />
                                    <span class="ts"></span>
                                </div>
                                <label class="right" for="toggleSwitch1">Collect directly</label>
                            </div>
                        </div>
                        <div class="t-c" *ngIf="Denmark">
                            <div class="toggle-row">
                                <div class="toggle-switch">
                                    <input type="checkbox" id="toggleSwitch2" formControlName="useNemkonto"
                                        (change)="toggleUseNemkonto()" />
                                    <span class="ts"></span>
                                </div>
                                <!-- ONLY SHOW THIS TOGGLE IF COUNTRY IS SET TO BE DENMARK -->
                                <label class="right" for="toggleSwitch2">Use NEMKONTO</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 pb-4" *ngIf="CollectDirectly && Denmark &&useNemkonto">
                        <div  class="alert alert-secondary" role="alert">
                            Transfers will be done to <b>insert Vat ID</b>
                        </div>
                      </div>
                    <div class="row mt-4 pb-4" *ngIf="CollectDirectly && Denmark &&!useNemkonto">
                        <div class=" col-6 input">
                          <label class="labelCompany">Registration Number</label>
                          <input type="text" formControlName="dK_RegNo" style="min-height: 60px;margin-top: 0.5rem;" />
                        </div>
                        <div class="col-6 input">
                          <label class="labelCompany">Account Number</label>
                          <input type="text" formControlName="dK_AccountNo" style="min-height: 60px;margin-top: 0.5rem;" />
                          <!-- <div class="validation">Please write a correct mail</div> -->
            
                        </div>
                      </div>
                      <div class="row mt-4 pb-4" *ngIf="CollectDirectly && Norway">
                        <div class="col-12 input">
                          <label class="labelCompany">Account Number</label>
                          <input type="text" formControlName="nO_AccountNo"style="min-height: 60px;margin-top: 0.5rem;" />
                          <!-- <div class="validation">Please write a correct mail</div> -->
            
                        </div>
                      </div>
                      <div class="row mt-4 pb-4" *ngIf="CollectDirectly && !Norway && !Denmark">
                        <div class="col-4 input">
                          <label class="labelCompany">Name of Bank</label>
                          <input type="text" formControlName="Intl_NameOfBank"style="min-height: 60px;margin-top: 0.5rem;" />            
                        </div>
                        <div class="col-4 input">
                            <label class="labelCompany">IBAN</label>
                            <input type="text"formControlName="Intl_IBAN" style="min-height: 60px;margin-top: 0.5rem;" />            
                          </div>
                          <div class="col-4 input">
                            <label class="labelCompany">Swift/BIC </label>
                            <input type="text"formControlName="Intl_Swicfbic"style="min-height: 60px;margin-top: 0.5rem;" />            
                          </div>
                      </div>
                      <div class="row mt-4 pb-4" *ngIf="!CollectDirectly ">
                        <p-dropdown [options]="parentLabels" formControlName="parentBoardId" inputId="parentLabel"
                        [filter]="true" filterBy="title" optionLabel="title" optionValue="id"
                        placeholder="Independent label (no parent label)"></p-dropdown>
                      </div>

                </section> <!-- Closing tag for paymentdetails -->

                <div class="row mt-4 pb-4">
                    <div class="col-6">
                        <section class="typeofcompany">
                            <div class="h">
                                <h4 class="headerPageCustom">Type of company</h4>
                                <p class="paragraphCustom">There are two types of companies: labels, which act as
                                    repertoire owners and can claim tracks, and agents, who represent repertoire owners
                                    but cannot assign tracks to their own agency.</p>
                            </div>

                            <div class="input">
                                <select id="dropdown1" name="options" formControlName="labelType" required>
                                    <option value="Label">Label</option>
                                    <option value="Agent">Agent</option>
                                </select>
                            </div>

                        </section> <!-- Closing tag for typeofcompany -->
                    </div>
                    <div class="col-6">
                        <section class="membertype">
                            <div class="h">
                                <h4 class="headerPageCustom">Membertype</h4>
                                <p class="paragraphCustom">There are two types of companies: labels, which act as
                                    repertoire owners and can claim tracks, and agents, who represent repertoire owners
                                    but cannot assign tracks to their own agency.</p>
                            </div>

                            <div class="input">
                                <select id="dropdown2" name="options" formControlName="memberType" required>
                                    <option value="Member">Member</option>
                                    <option value="Rightsholder">Rightsholder</option>
                                </select>

                            </div>
                        </section> <!-- Closing tag for membertype -->
                    </div>
                </div>

                <div class="row mt-4 pb-4">
                    <div class="col-6">
                        <section class="status">
                            <div class="h">
                                <h4 class="headerPageCustom">Status</h4>
                                <p class="paragraphCustom">Labels are typically active, but in rare cases, deactivation
                                    is necessary. A deactivated label will prevent associated users from signing in but
                                    will be retained for potential future reactivation.</p>
                            </div>

                            <div class="input" style="padding-top:34px">
                                <select id="dropdown3" name="options" disabled>
                                    <option value="active" selected="selected">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>

                        </section> <!-- Closing tag for status -->
                    </div>
                    <div class="col-6">
                        <section class="internalfinancialidentifier">
                            <div class="h">
                                <h4 class="headerPageCustom">Internal financial identifier</h4>
                                <p class="paragraphCustom">Labels are typically active, but in rare cases, deactivation
                                    is necessary. A deactivated label will prevent associated users from signing in but
                                    will be retained for potential future reactivation.</p>
                            </div>
                            <div class="t-c">
                                <div class="toggle-row">
                                    <div class="toggle-switch">
                                        <input type="checkbox" id="toggleSwitch3"
                                        [checked]="toggleSwitchIdentifier" 
                                        (change)="onToggleChange($event)"
                                        
                                        />
                                        <span class="ts"></span>
                                    </div>
                                    <label class="right" for="toggleSwitch3">Use available VAT ID</label>
                                </div>
                            </div>

                            <div class="input">
                                <input type="text" style="min-height: 60px;margin-top: 0.5rem;" formControlName="financialIdentifier" />
                            </div>

                        </section> <!-- Closing tag for internalfinancialidentifier -->
                    </div>
                </div>


                <div class="row mt-4 pb-4" style="border-bottom:none !important ;">
                    <div class="col-6">
                        <section class="status">
                            <div class="h">
                              <h4 class="headerPageCustom">Memberships</h4>
                              <p class="paragraphCustom">The organization is important for both analytical purposes and potential deductions in distributions.</p>
                            </div>
                        <div class="toggles">
                            <div class="t-c" *ngIf="Norway">
                            <!-- <label for="toggleSwitch" class="top">Use VAT ID</label> -->
                            <div class="toggle-row">
                            <div class="toggle-switch">
                            <input type="checkbox"  id="toggleSwitch1" />
                            <span class="ts"></span>
                            </div>
                            <label class="right" for="toggleSwitch2">IFPI Norge</label>
                            </div>
                            </div>
                            <div class="t-c" *ngIf="Norway">
                            <!-- <label for="toggleSwitch" class="top">Use VAT ID</label> -->
                            <div class="toggle-row">
                            <div class="toggle-switch">
                            <input type="checkbox" id="toggleSwitch1" />
                            <span class="ts"></span>
                            </div>
                            <!-- ONLY SHOW THIS TOGGLE IF COUNTRY IS SET TO BE DENMARK -->
                            <label class="right" for="toggleSwitch1">FONO</label>
                            </div>
                            </div>
                            <div class="t-c" *ngIf="Denmark">
                                <!-- <label for="toggleSwitch" class="top">Use VAT ID</label> -->
                                <div class="toggle-row">
                                <div class="toggle-switch">
                                <input type="checkbox" id="toggleSwitch1" />
                                <span class="ts"></span>
                                </div>
                                <!-- ONLY SHOW THIS TOGGLE IF COUNTRY IS SET TO BE DENMARK -->
                                <label class="right" for="toggleSwitch1">IFPI Danmark</label>
                                </div>
                                </div>
                            </div>
</section>
                    </div>
                    <div class="col-6">
                        <section class="deletecompany" style="border: none;">
                            <h4 class="headerPageCustom">Delete company</h4>
                            <p class="paragraphCustom">This action is irreversible. It is generally recommended to only
                                deactivate a label instead.</p>
                            <button class="btn red" (click)=" openDeleteLabelDialog()">Delete company</button>
                        </section> <!-- Closing tag for deletecompany -->
                    </div>
                </div>
        
            </div> <!-- Closing tag for the row -->
        </form>
    </div> <!-- Closing tag for card body -->
</div> <!-- Closing tag for card -->
<div class="unsaved-changes-alert" [ngClass]="animationState" *ngIf="companyForm.dirty ">
    <p class="fw-bold mb-0">You have unsaved changes!</p>
    <button pButton class="discardbtn" (click)="cancel()">Discard changes</button>
    <button pButton class="savebtn" (click)="UpdateCompany()">
        Save changes
    </button>
</div>
<p-dialog [(visible)]="displayDeleteLabelDialog" [modal]="true" [style]="{ 'width': '40rem', 'cursor': 'initial' }"
    [draggable]="false">
    <ng-template pTemplate="header">
        <p class="fw-bold h2 mb-0">Delete label</p>
    </ng-template>
    <p>Are you sure you want to delete <span class="fw-bold">{{ selectedLabel }}</span>?</p>
    <p>
        The label will no longer be visible. All claims, assigned and
        auto-assigned items for this label won’t be reflected in open reports
        anymore.
    </p>
    <p>Enter label name to confirm deletion</p>
    <input type="text" pInputText class="w-100" [(ngModel)]="deleteLabelValue" (input)="checkInputContent()" />
    <small *ngIf="showError" class="error-message">This field is invalid</small>
    <div class="buttons-dialog">
        <p-button label="Cancel" styleClass="p-button-secondary" (click)="closeDeleteLabelDialog()"></p-button>
        <p-button label="Delete label" styleClass="p-button-danger ms-2" (click)="deleteLabel()"
            [disabled]="!isInputValid"></p-button>
    </div>
</p-dialog>