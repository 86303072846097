    <div class="card companyCoard">
        <div class="card-body">
    <section class="labelidentifier">
      <h4 class="headerPageCustom">
        Add internal label identifier (feature still in development)
      </h4>
      <p class="paragraphCustom">
        To automatically link your uploaded search file with this
        label, enter an identifier below. Use the same identifier in
        your Excel file when using the “Upload file and search”
        function, and the file will link to this label.
      </p>

      <div class="t-c">
        <!-- <label for="toggleSwitch" class="top">Use VAT ID</label> -->
        <div class="toggle-row">
          <div class="toggle-switch">
            <input type="checkbox" id="toggleSwitch" 
            [checked]="toggleSwitchIdentifier" 
            (change)="onToggleChange($event)"
            
            />
            <span class="ts"></span>
          </div>
          <label class="right" for="toggleSwitch">Use VAT ID</label>
        </div>
      </div>
      <form [formGroup]="companyForm" >
      <div class="row mt-1">
        <div class="input col-12">
          <label class="labelCompany" for="dropdown">Identifier</label>
          <input type="text"  style="width: 100%;margin-top: 0.5rem;"  formControlName="identifier" />
        </div>
      </div>
    </form>
    </section>
  </div>
</div>

<div
  class="unsaved-changes-alert"
  [ngClass]="animationState"
  *ngIf="companyForm.dirty "
>
  <p class="fw-bold mb-0">You have unsaved changes!</p>
  <button pButton class="discardbtn" (click)="cancel()">Discard changes</button>
  <button pButton class="savebtn" (click)="UpdateCompany()">
    Save changes
  </button>
</div>
